// import 'vite/modulepreload-polyfill'
import alpineUI from '@alpinejs/ui'
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse' 
import intersect from '@alpinejs/intersect'
import persist from '@alpinejs/persist'
import fade from './alpine-components/_fade.js'

import cookieStorage from './partials/_cookie-storage';

import Player from '@vimeo/player'
import Splide from '@splidejs/splide';

import '../css/app.css'
import '@splidejs/splide/css/core'

alpineUI(Alpine)
 
Alpine.plugin(focus)
Alpine.plugin(collapse)
Alpine.plugin(intersect)
Alpine.plugin(persist)
Alpine.plugin(fade);

window.Alpine = Alpine
window.Vimeo = Player
window.Splide = Splide

Alpine.store('header', {
    init() {
        this.setNavStatus()
    },

    stuck: false,

    open: false,

    toggleable: false,

    toggleNav() {
        this.open = ! this.open
    },

    setNavStatus() {
        this.toggleable = ! window.matchMedia('(min-width: 768px)').matches;
        this.open = window.matchMedia('(min-width: 768px)').matches;
    }
});
 
Alpine.start()


jQuery(function ($) {
        // Get the target date from the .countdown div
        var targetDate = new Date($('.countdown').text().trim()).getTime();

        function updateCountdown() {
            var now = new Date().getTime();
            var timeLeft = targetDate - now;

            if (timeLeft > 0) {
                // Calculate time components
                var days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
                var hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

                // Update the HTML content
                $('.timer .days').text(days);
                $('.timer .hours').text(hours);
                $('.timer .minutes').text(minutes);
                $('.timer .seconds').text(seconds);
            } else {
                // If the countdown has ended
                $('.timer').html('<div>Countdown Completed!</div>');
                clearInterval(interval);
            }
        }

        // Update the countdown every second
        var interval = setInterval(updateCountdown, 1000);

        // Call the function immediately to display the timer without delay
        updateCountdown();
    });